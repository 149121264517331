import React from 'react'
import {Link} from 'gatsby'

const Breadcrumbs = (props) => {
  const { root, crumbs } = props
  let url = root ? root : ''

  const appendCurrentLocation = (location) => {
    url += `/${location}`
    return url
  }

  return (
    <div className='breadcrumbs absolute z-20 top-0 mt-15 md:mt-25 px-5 lg:px-12 xl:px-20'>
      {crumbs && (
        <>
          <span>
            <Link to='/'>hanshelse.no</Link>
          </span>
          {crumbs.map((crumb, index) => (
            <span key={index}>
              <span> / </span>
              {crumbs.length === index + 1 ? (
                <span>{crumb.title}</span>
              ) : (
                <Link to={appendCurrentLocation(crumb.slug.current)}>{crumb.title}</Link>
              )}
            </span>
          ))}
        </>
      )}
    </div>
  )
}

export default Breadcrumbs