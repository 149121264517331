import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'
import favicon from '../images/favicon.png';
import appleTouchIcon from '../images/appleTouchIcon.png';

function SEO ({ description, page, lang, meta, title }) {
  return (
    <StaticQuery
      query={detailsQuery}
      render={data => {
        const metaDescription = description || (page && page.seo && page.seo.description) || ''
        title = title || (page && page.seo && page.seo.title) || (page && page.title)
        const siteTitle = (data.site && data.site.title) || ''
        const faviconLinks = [
          { rel: 'icon', type: 'image/png', sizes: '32x32', href: favicon },
          { rel: 'apple-touch-icon', href: appleTouchIcon },
        ]

        meta = [
          {
            property: 'og:title',
            content: title
          },
          {
            property: 'og:type',
            content: 'website'
          },
          {
            name: 'twitter:card',
            content: 'summary'
          },
          {
            name: 'twitter:title',
            content: title
          },
        ].concat(meta)

        if (metaDescription) {
          meta = [
            {
              name: 'description',
              content: metaDescription
            },
            {
              name: 'twitter:description',
              content: metaDescription
            },
            {
              property: 'og:description',
              content: metaDescription
            }
          ].concat(meta)
        }

        return (
          <Helmet
            htmlAttributes={{ lang }}
            title={title}
            titleTemplate={title === siteTitle ? '%s' : `%s | ${siteTitle}`}
            link={faviconLinks}
            meta={meta}
          />
        )
      }}
    />
  )
}

SEO.defaultProps = {
  lang: 'nb',
  meta: []
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.array,
  title: PropTypes.string
}

export default SEO

const detailsQuery = graphql`
  query DefaultSEOQuery {
    site: sanitySiteSettings(_id: {regex: "/(drafts.|)siteSettings/"}) {
      title
    }
  }
`
