import React from 'react'
import { Link } from 'gatsby'
import BlockContent from '../components/block-content'

export const mapEdgesToNodes = (data) => {
  if (!data.edges) return []
  return data.edges.map(edge => edge.node)
}

export const faqToAccordion = (data) => {
  return data.map((node, index) => {
    var content = (
      <>
        <BlockContent blocks={node._rawAnswer} className='editorial' />
        {node.slug && node.slug.current && (
          <Link className='text-xs opacity-50 hover:opacity-100' to={`/kundeservice/${node.slug.current}`}>Åpne spørsmålet på egen side</Link>
        )}
      </>
    )

    return {
      title: node.question,
      content
    }
  })
}

export function getBlogUrl (categorySlug, blogSlug) {
  return `/${categorySlug}/${blogSlug}`
}

export const cn = (...args) => {
  return args.filter(Boolean).join(' ')
}